var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        justify: "center",
        value: _vm.value,
        persistent: "",
        "max-width": "800px",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-toolbar",
            { staticClass: "main", attrs: { flat: "", dark: "" } },
            [
              _c("v-toolbar-title", [_vm._v("Warning")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "grey darken-2" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "ma-0 pa-6 warningModal" },
            [
              _c("v-row", { attrs: { justify: "center" } }, [
                _vm.partiesGap.length > 0
                  ? _c(
                      "div",
                      { staticClass: "pa-4" },
                      [
                        _vm._v(
                          ' A file named "' +
                            _vm._s(_vm.documentFileName) +
                            '" with this category and subcategory already exists. Uploading will overwrite the existing file. The new file is missing the following parties: '
                        ),
                        _c("v-data-table", {
                          staticClass: "partyGapTable",
                          attrs: {
                            height: "200px",
                            headers: _vm.headers,
                            items: _vm.partiesGap,
                            "items-per-page": 100,
                            "item-key": "party_id",
                            "fixed-header": "",
                            "hide-default-footer": "",
                            dense: "",
                          },
                          on: {
                            "update:items": function ($event) {
                              _vm.partiesGap = $event
                            },
                          },
                        }),
                        _c("br"),
                        _vm._v(" Are you sure you want to continue? "),
                      ],
                      1
                    )
                  : _c("span", { staticClass: "pa-4" }, [
                      _vm._v(
                        ' A file named "' +
                          _vm._s(_vm.documentFileName) +
                          '" with this category and subcategory already exists. Uploading will overwrite the existing file. Are you sure you want to continue? '
                      ),
                    ]),
              ]),
              _c(
                "v-row",
                {
                  staticClass: "pa-4 align-end ma-auto",
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "elevation-1",
                      attrs: { height: "40", outlined: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$emit("reject")
                        },
                      },
                    },
                    [_vm._v(" No ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text ml-2",
                      attrs: { color: "blue", height: "40" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("confirm")
                        },
                      },
                    },
                    [_vm._v("Yes ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }